import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { appError, setLiveAppCompleted } from '../../../../../redux/slices/app'
import api from '../../../../../utils/api'
import { InitPaymentParams } from "../../../../../utils/types"
import Utils from '../../../../../utils/utils'
import { useCaptcha } from '../../../../Presentational/CaptchaModal'
import { submitPostForm } from './utils'
import useDepositResultPage from "./useDepositResultPage"
import { useAppState } from "../../../../../utils/hooks"

export interface ProcessorLikeObject {
  id: number | string
  name: string
}

export interface AccountLikeObject {
  account: {
    id: number
  }
}

export const useInitiatePayment = (processor: ProcessorLikeObject | undefined, account: AccountLikeObject | undefined, submitForm: () => void) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { redirectedFromWTR } = useAppState()
  const { captcha, showCaptcha, captchaModal } = useCaptcha(submitForm)
  const getResultPage = useDepositResultPage()

  const initiatePayment = async (
    amount: string,
    currencyId: string | number,
    additionalParamOptions?: Partial<InitPaymentParams['options']>,
    onIframeOpen?: (redirectUrl: string) => void,
    ignoreResultAction?: boolean
  ) => {
    try {
      if (!processor || !account) {
        return;
      }

      let data: InitPaymentParams = {
        accountId: account.account.id,
        currencyId: currencyId,
        amount: amount,
        processorId: processor.id,
        sourcePortal: 'C_PORTAL',
        resultPage: getResultPage(undefined, redirectedFromWTR),
        options: { ...additionalParamOptions }
      }

      const response = await api.initiatePayment(data, captcha)
      if (Utils.validResponse(response)) {
        const result = response.payload[0].result
        if (result.action?.type) {
          switch (result.action.type) {
            case 'nop':
              if (ignoreResultAction) {
                break
              }

              history.push(getResultPage(result.transactionId))
              break
            case 'post':
              const acceptCharset = processor.name === 'Quick Payment' ? 'Shift-JIS' : 'UTF-8'
              if (redirectedFromWTR) {
                Utils.postMessageToWTR({
                  message: 'depositRedirect',
                  acceptCharset: acceptCharset,
                  action: result.action
                })
              } else {
                const resultFields = processor.name === 'CheckoutCom' ? {} : result.action.fields
                const resultURL = processor.name === 'CheckoutCom' ? result.action.url.replace(/"/g, '') : result.action.url
                submitPostForm(result.action.type, resultURL, resultFields, acceptCharset)
              }
              break
            case 'redirect':
              if (redirectedFromWTR) {
                Utils.postMessageToWTR({
                  message: 'depositRedirect',
                  action: result.action
                })
              } else {
                window.open(result.action.url, '_self')
              }
              break
            case 'iframe':
              onIframeOpen && onIframeOpen(result.action.url)
              break
          }
        }
      }
      const { status } = response.payload[0]
      if (status === 'CHALLENGE') {
        showCaptcha()
      } else if (!Utils.validResponse(response)) {
        dispatch(appError(Utils.statusError(response)))
        return status
      }

    } catch (e) {
      dispatch(appError(Utils.customError(e.message)))
    }
    finally { dispatch(setLiveAppCompleted(true)) }
  }

  return { initiatePayment, captchaModal }
}
